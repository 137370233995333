import { useEffect } from "react";
import { appWithTranslation } from "next-i18next";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import TagManager from "react-gtm-module";
import "../styles/globals.scss";
import config from "../config";
import { i18n } from "../../next-i18next.config";
import { GameProvider } from "../context/GameContext";
import nextI18NextConfig from "../../next-i18next.config";

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    TagManager.initialize({ gtmId: config.gtmId });
  }, []);

  return (
    <GoogleReCaptchaProvider language={i18n.language} reCaptchaKey={config.recaptcha.siteKey}>
      <GameProvider>
        <Component {...pageProps} />
      </GameProvider>
    </GoogleReCaptchaProvider>
  );
}

export default appWithTranslation(MyApp, nextI18NextConfig);
