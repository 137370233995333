const config = {
  recaptcha: {
    siteKey: "6LddJXwUAAAAAH-LZMLNSvR-oakCnEXjUiysDlfO",
    url: "https://www.google.com/recaptcha/api/siteverify",
  },
  gtmId: "GTM-5ZN46KR",
  environment: "development",
  message: "https://server-message-staging.sbfs.io",
  messageEmail: "a2V2aW5Ac3ViZm9yY2UuaW8=",
};

if (typeof window !== "undefined") {
  if (window.location.hostname === "staging.friend-code.com") {
    config.environment = "staging";
    config.message = "https://server-message-staging.sbfs.io";
    config.messageEmail = "a2V2aW5Ac3ViZm9yY2UuaW8=";
  }
}

if (typeof window !== "undefined") {
  if (window.location.hostname === "friend-code.com") {
    config.gtmId = "GTM-5FPMQJT";
    config.environment = "production";
    config.message = "https://server-message.sbfs.io";
    config.messageEmail = "aGVsbG9Ac3ViZm9yY2UuaW8=";
  }
}

export default config;
