const path = require("path");

module.exports = {
  i18n: {
    defaultLocale: "default",
    locales: ["en", "fr", "es", "default"],
    localeDetection: false,
    localePath: path.resolve("./public/locales"),
    preload: true,
  },
  serializeConfig: false,
};
